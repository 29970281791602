import React from "react";
import './signup.css';
import { NavLink } from "react-router-dom";
import Axios from 'axios';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import swal from 'sweetalert';

class SignUp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            domain: '',
            name: '',
            phone: '',
            email: '',
            password: '',
            activeButton: 'false'
        }
    }
    componentDidMount(){
        document.getElementsByClassName("my-facebook-button-class")[0].value = "Sign Up";
    }

    handleDomain = (e) => {

        document.getElementById("domain").value = document.getElementById("domain").value.trim()

        if (e.target.value.trim() === "") {
            e.target.value = "";
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }
        if (e.target.value !== "") {
            document.getElementById("error" + e.target.name).innerHTML = "";
        }

        var normalTextRegEx = /^[A-Za-z]+[A-Za-z]+[A-Za-z]+[A-Za-z]+[A-Za-z]+$/;

        if (e.target.name === "domain") {
            this.setState({ activeButton: 'false' });
            if (document.getElementById("domain").value !== "") {
                if (document.getElementById("domain").value.match(normalTextRegEx)) {
                    let formData = new FormData();
                    formData.append('domain', document.getElementById("domain").value.trim());
                    Axios.post(
                        "https://digitizedresume.com/api/user/domainCheck.php",
                        formData
                    ).then((res) => {
                        document.getElementById("errordomain").innerHTML =
                            res.data;
                        // alert(res.data.trim());
                        if (res.data.trim() === "This domain is available!") {
                            this.setState({ activeButton: "true" });
                        }
                    });
                }
                else {
                    document.getElementById("errordomain").innerHTML = "Please enter a valid domain of at least 5 alphabets long.";
                }
            }
            else {
                document.getElementById("errordomain").innerHTML = "This field is required. Please enter domain.";
            }
        }


    }

    showSignUp = (e) => {
        if (this.state.activeButton === 'true') {
            document.getElementById("signuppage").style.display = "block";
            document.getElementById("search_domain").style.display = "none";
            var domain = this.state.domain;
            this.setState({ domain: domain });
        }
        else {
            document.getElementById("errordomain").innerHTML = "Please enter a valid domain of at least 5 alphabets long.";
        }

    }

    handle = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        // alert(e.target.value);
        if (e.target.value !== "") {
            document.getElementById("error" + e.target.name).innerHTML = "";
        }
        var normalTextRegEx = /^[a-zA-Z0-9]+([ a-zA-Z0-9])+/;
        var mobileNumberRegEx = /^[6789][0-9]{9}$/;
        var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        if (e.target.name === "name") {
            if (document.getElementById("name").value !== "") {
                if (document.getElementById("name").value.match(normalTextRegEx)) {
                    document.getElementById("errorname").innerHTML = "";
                }
                else {
                    document.getElementById("errorname").innerHTML = "Please enter a valid name.";
                }
            }
            else {
                document.getElementById("errorname").innerHTML = "This field is required. Please enter name.";
            }
        }

        if (e.target.name === "phone") {
            if (document.getElementById("phone").value !== "") {
                if (document.getElementById("phone").value.match(mobileNumberRegEx)) {
                    document.getElementById("errorphone").innerHTML = "";
                }
                else {
                    document.getElementById("errorphone").innerHTML = "Please enter a valid 10 digit number.";
                }
            }
            else {
                document.getElementById("errorphone").innerHTML = "This field is required. Please enter number.";
            }
        }

        if (e.target.name === "email") {
            if (document.getElementById("email").value !== "") {
                if (document.getElementById("email").value.match(emailRegEx)) {
                    document.getElementById("erroremail").innerHTML = "";
                }
                else {
                    document.getElementById("erroremail").innerHTML = "Please enter a valid email.";
                }
            }
            else {
                document.getElementById("erroremail").innerHTML = "This field is required. Please enter email.";
            }
        }

        if (e.target.name === "password") {
            if (document.getElementById("password").value !== "") {
                if (document.getElementById("password").value.length >= 5) {
                    document.getElementById("errorpassword").innerHTML = "";
                }
                else {
                    document.getElementById("errorpassword").innerHTML = "Please enter at least 5 digit password.";
                }
            }
            else {
                document.getElementById("errorpassword").innerHTML = "This field is required. Please enter password.";
            }
        }


    }

    acceptTC = (e) => {
        var tc;
        if (document.getElementById("acceptTC").checked === true) {
            tc = "Yes";
            document.getElementById("signUpButton").disabled = false;
            this.setState({
                acceptTC: tc
            })
            document.getElementById("erroracceptTC").innerHTML = "";
        }
        else if (document.getElementById("acceptTC").checked === false) {
            tc = "No";
            document.getElementById("signUpButton").disabled = true;
            this.setState({
                acceptTC: tc
            })
            document.getElementById("erroracceptTC").innerHTML = "This field is required. Please select.";
        }
    }

    // onSignIn = (googleUser) => {
    //     var profile = googleUser.getBasicProfile();
    //     console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    //     console.log('Name: ' + profile.getName());
    //     console.log('Image URL: ' + profile.getImageUrl());
    //     console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
    // }

    // signOut = () => {
    //     var auth2 = window.gapi.auth2.getAuthInstance();
    //     auth2.signOut().then(function () {
    //         console.log('User signed out.');
    //     });
    // }
    // onSignIn();

    submit = (e) => {
        e.preventDefault();
        var normalTextRegEx = /^[a-zA-Z0-9]+([ a-zA-Z0-9])+/;
        var mobileNumberRegEx = /^[6789][0-9]{9}$/;
        var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        var check = 0;

        if (document.getElementById("name").value !== "") {
            if (document.getElementById("name").value.match(normalTextRegEx)) {
                document.getElementById("errorname").innerHTML = "";
            }
            else {
                check++;
                document.getElementById("errorname").innerHTML = "Please enter a valid name.";
            }
        }
        else {
            check++;
            document.getElementById("errorname").innerHTML = "This field is required. Please enter name.";
        }


        if (document.getElementById("phone").value !== "") {
            if (document.getElementById("phone").value.match(mobileNumberRegEx)) {
                document.getElementById("errorphone").innerHTML = "";
            }
            else {
                check++;
                document.getElementById("errorphone").innerHTML = "Please enter a valid 10 digit number.";
            }
        }
        else {
            check++;
            document.getElementById("errorphone").innerHTML = "This field is required. Please enter number.";
        }


        if (document.getElementById("email").value !== "") {
            if (document.getElementById("email").value.match(emailRegEx)) {
                document.getElementById("erroremail").innerHTML = "";
            }
            else {
                check++;
                document.getElementById("erroremail").innerHTML = "Please enter a valid email.";
            }
        }
        else {
            check++;
            document.getElementById("erroremail").innerHTML = "This field is required. Please enter email.";
        }


        if (document.getElementById("password").value !== "") {
            if (document.getElementById("password").value.length >= 5) {
                document.getElementById("errorpassword").innerHTML = "";
            }
            else {
                check++;
                document.getElementById("errorpassword").innerHTML = "Please enter at least 5 digit password.";
            }
        }
        else {
            check++;
            document.getElementById("errorpassword").innerHTML = "This field is required. Please enter password.";
        }

        var tc;
        if (document.getElementById("acceptTC").checked === true) {
            tc = "Yes";
            document.getElementById("erroracceptTC").innerHTML = "";
            document.getElementById("submit").disabled = false;
            this.setState({
                acceptTC: tc
            })
        }
        else if (document.getElementById("acceptTC").checked === false) {
            tc = "No";
            document.getElementById("submit").disabled = true;
            this.setState({
                acceptTC: tc
            })
            document.getElementById("erroracceptTC").innerHTML = "This field is required. Please select.";
        }

        if (check === 0) {

            // alert("In");
            // eslint-disable-next-line new-parens
            let formData = new FormData();
            formData.append('name', this.state.name);
            formData.append('email', this.state.email);
            formData.append('phone', this.state.phone);
            formData.append('domain', this.state.domain);
            formData.append('password', this.state.password);
            // console.log(obj)
            Axios.post(
                "https://digitizedresume.com/api/user/insert.php",
                formData
            ).then((res) => {
                if (res.data === 1) {
                    this.setState({ domain: "" });
                    document.getElementById("signUpButton").disabled = true;
                    this.setState({ activeButton: "false" });
                    document.getElementById("userRegistrationForm").reset();
                    document.getElementById("signuppage").style.display = "none";
                    document.getElementById("search_domain").style.display = "block";
                    swal({
                        title: "Registered!",
                        text: "Successfully registered. And redirecting to login...!",
                        button: "Close",
                        icon: "success",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                    setTimeout(function () {
                        window.location.href = "https://dashboard.digitizedresume.com/";
                    }, 3000);
                }
                else {
                    swal({
                        title: "Error!",
                        text: "This email id is already registered!",
                        button: "Close",
                        icon: "error",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                    setTimeout(function () {
                        document.getElementById("alert2").style.display = "none";
                    }, 3000);
                }
            });
        }
    }

    responseGoogle = (googleUser) => {
        var profile = googleUser.getBasicProfile();
        // console.log("ID: " + profile.getId()); // Don't send this directly to your server!
        // console.log('Full Name: ' + profile.getName());
        // console.log('Given Name: ' + profile.getGivenName());
        // console.log('Family Name: ' + profile.getFamilyName());
        // console.log("Image URL: " + profile.getImageUrl());
        // console.log("Email: " + profile.getEmail());

        // The ID token you need to pass to your backend:
        // var id_token = googleUser.getAuthResponse().id_token;
        // console.log("ID Token: " + id_token);
        // console.log(googleUser);

        var id = profile.getId();
        var name = profile.getName();
        var email = profile.getEmail();

        if (profile.getId() !== "") {
            var auth2 = window.gapi.auth2.getAuthInstance();
            auth2.signOut().then(function () {
                // console.log('User signed out.');
            });
            let formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('accountType', "Google");
            formData.append('domain', this.state.domain);
            formData.append('gmailLoginId', id);
            Axios.post(
                "https://digitizedresume.com/api/user/insertGoogleLogin.php",
                formData
            ).then((res) => {
                // alert(res.data);
                if (res.data === 1) {
                    this.setState({ domain: "" });
                    document.getElementById("userRegistrationForm").reset();
                    document.getElementById("domain").value = "";
                    document.getElementById("signuppage").style.display = "none";
                    document.getElementById("search_domain").style.display = "block";
                    swal({
                        title: "Registered!",
                        text: "Successfully registered. And redirecting to login...!",
                        button: "Close",
                        icon: "success",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                    setTimeout(function () {
                        window.location.href = "https://dashboard.digitizedresume.com/";
                    }, 3000);
                }
                else {
                    swal({
                        title: "Error!",
                        text: "This gmail id is already registered!",
                        button: "Close",
                        icon: "error",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                }
            });
        }
        else {
            swal({
                title: "Error!",
                text: "Something went wrong, try again!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }

    responseFacebook = (response) => {
        if (response.status === 'connected') {
            // The user is logged in and has authenticated your
            // app, and response.authResponse supplies
            // the user's ID, a valid access token, a signed
            // request, and the time the access token 
            // and signed request each expire.
            var uid = response.authResponse.userID;
            var accessToken = response.authResponse.accessToken;
        } else if (response.status === 'not_authorized') {
            // The user hasn't authorized your application.  They
            // must click the Login button, or you must call FB.login
            // in response to a user gesture, to launch a login dialog.
        } else {
            // The user isn't logged in to Facebook. You can launch a
            // login dialog with a user gesture, but the user may have
            // to log in to Facebook before authorizing your application.
        }
        console.log(response);
    }

    render() {
        return (
            <>
                <div className="container-fluid signup_section">
                    <div className="row mt-4">
                        <div className="col-md-12 text-center">
                            <h1 className="heading_title text-dark" data-aos="fade-up" data-aos-duration="2000"> Create Your Digital Resume</h1>
                            <img src="../../assets/image/Arrow1.svg" className="arrow_img" alt="" data-aos='fade-up' data-aos-duration="2000" />
                        </div>

                        <div className="col-md-12 my-5 search_domain" id="search_domain">
                            <div className="row search_domain p-5">
                                <div className="col-md-3 text-center">
                                    <h6>Create Your Domain Here </h6>
                                </div>
                                <div className="col-md-6">
                                    <form>
                                        <input type="text" onInput={this.handleDomain} onKeypress="return event.keyCode != 13;" id="domain" name="domain" className="domain_search" placeholder="Search your domain or name to create and show your profile...)" />
                                        <p className="text-dark font-bold" id="errordomain"></p>
                                    </form>
                                </div>
                                <div className="col-md-2">
                                    <button className="srch-btn" onClick={this.showSignUp} id="submit">Build Resume</button>
                                </div>
                                {/* <a href="#" onClick={this.signOut}>Sign out</a> */}
                            </div>
                        </div>

                        <div className="col-md-12 text-center desc_resume">
                            <img src="../../assets/image/Arrow2.svg" />
                            <p>To create digitizedresume search your domain name or name for resume,<br></br> where you can see your digital resume. It will be like https://digitizedresume.com/your-domain</p>

                        </div>



                        <div className="col-md-4"> </div>

                        <div className="col-md-4 signup_page shadow my-3" id="signuppage">
                            <h5>Employee - Sign Up to <span className="digibot"> Digitized</span> Resume</h5>

                            <form className="text-center mt-4" id="userRegistrationForm" onSubmit={this.submit}>
                                <input type="text" disabled style={{ color: 'black', background: 'white' }} value={this.state.domain} name="number" placeholder="https://digitizedresume.com/" />
                                <input type="text" onChange={this.handle} name="name" id="name" style={{ textTransform: 'capitalize' }} placeholder="Enter name" />
                                <p className="error" id="errorname"></p>
                                <input type="number" onChange={this.handle} name="phone" id="phone" placeholder="Enter number" />
                                <p className="error" id="errorphone"></p>
                                <input type="email" onChange={this.handle} name="email" id="email" placeholder="Enter email" />
                                <p className="error" id="erroremail"></p>
                                <input type="password" onChange={this.handle} name="password" id="password" placeholder="Enter password" />
                                <p className="error" id="errorpassword"></p>

                                <div class="form-check mt-1">
                                    <input type="checkbox" id="acceptTC" onClick={this.acceptTC} name="acceptTC" className="" value="" />
                                    <span className="remember text-dark">I accept <NavLink to="/terms-of-condition" className="text-primary text-decoration-underline" target="_blank"> Terms and Condition, </NavLink> and <NavLink to="/privacy-policy" target="_blank" className="text-primary text-decoration-underline"> Privacy Policy </NavLink></span>
                                    <p className="error" id="erroracceptTC"></p>
                                </div>

                                <button class="btn btn-style mt-3" disabled id="signUpButton">Sign Up </button>

                                <p class="already">Already have an account? <a href="https://dashboard.digitizedresume.com">Sign in Now</a></p>

                            </form>
                            <div className="social-media text-center col-md-12 my-2">
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        {/* <FacebookLogin
                                            appId="1205168516650170"
                                            autoLoad={false}
                                            fields="name,email,picture"
                                            callback={this.responseFacebook}
                                            cssClass="my-facebook-button-class"
                                            icon="fa-facebook"
                                        /> */}
                                        <button className="my-facebook-button-class"><i className="fab fa-facebook"></i> Sign Up</button>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <GoogleLogin
                                            clientId="823375778045-sstq5nttdign73ubrkvkio6ja5elmrb3.apps.googleusercontent.com"
                                            buttonText="Sign Up"
                                            onSuccess={this.responseGoogle}
                                            onFailure={this.responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4"> </div>
                    </div>
                </div>
            </>
        )
    }
}
export default SignUp