import React from "react"
import './menu.css';
import logo from "./logo.svg";
import { Link, NavLink } from "react-router-dom";

class Menu extends React.Component{

    home = () =>{
       if(document.getElementById("home").style.color === "#1b1c1d"){
           document.getElementById("home").style.color = "orangered";
           document.getElementById("employee").style.color = "#1b1c1d";
           document.getElementById("about").style.color = "#1b1c1d";
           document.getElementById("contact").style.color = "#1b1c1d";
           document.getElementById("login").style.color = "#1b1c1d";
       }
    
    }
    employee = () =>{
        if(document.getElementById("employee").style.color === "#1b1c1d"){
            document.getElementById("employee").style.color = "orangered";
            document.getElementById("home").style.color = "#1b1c1d";
            document.getElementById("about").style.color = "#1b1c1d";
            document.getElementById("contact").style.color = "#1b1c1d";
            document.getElementById("login").style.color = "#1b1c1d";
        }
    }
    about = () =>{
        if(document.getElementById("about").style.color === "#1b1c1d"){
            document.getElementById("about").style.color = "orangered";
            document.getElementById("employee").style.color = "#1b1c1d";
            document.getElementById("home").style.color = "#1b1c1d";
            document.getElementById("contact").style.color = "#1b1c1d";
            document.getElementById("login").style.color = "#1b1c1d";
        }
    }
    contact = () =>{
        if(document.getElementById("contact").style.color === "#1b1c1d"){
            document.getElementById("contact").style.color = "orangered";
            document.getElementById("employee").style.color = "#1b1c1d";
            document.getElementById("about").style.color = "#1b1c1d";
            document.getElementById("home").style.color = "#1b1c1d";
            document.getElementById("login").style.color = "#1b1c1d";
        }
    }
    login = () =>{
        if(document.getElementById("login").style.color === "#1b1c1d"){
            document.getElementById("login").style.color = "orangered";
            document.getElementById("employee").style.color = "#1b1c1d";
            document.getElementById("about").style.color = "#1b1c1d";
            document.getElementById("contact").style.color = "#1b1c1d";
            document.getElementById("home").style.color = "#1b1c1d";
        }
    }

    render(){
        return(
            <>
                <nav className="navbar navbar-expand-lg shadow-sm" id="menuid">
                    <div className="container-fluid">
                        {/* logo section */}
                    <a className="navbar-brand" href="javascript:void(0);">
                        <img src={logo} alt="" />
                    </a>

                    <div className="social_media">
                        <i class="fab fa-facebook-square"></i>
                        <i class="fab fa-twitter-square"></i>
                        <i class="fab fa-linkedin"></i>
                        <i class="fab fa-youtube-square"></i>
                    </div>


                    <button className="menu_button shadow-sm" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="fa fa-bars"></span>
                    </button>


                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink exact to="/"> <a className="nav-link" aria-current="page" href="javascript:void(0);">Home</a> </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact to="/employee"> <a className="nav-link" href="javascript:void(0);">Our Talent</a> </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact to="/about_us">  <a className="nav-link" href="javascript:void(0);">About</a> </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact to="/user_testimonial">  <a className="nav-link" href="javascript:void(0);">Testimonial</a> </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact to="/contact" className="nav-link">Contact</NavLink>
                            </li>
                            <li className="nav-item login_button shadow-sm">
                                <a href="https://dashboard.digitizedresume.com/" className="nav-link">Login</a>
                            </li>
                            <li className="nav-item login_button shadow-sm">
                                <a href="https://company.digitizedresume.com/" className="nav-link">Employer</a>
                            </li>
                        </ul>
                    </div>
                    </div>
                </nav>


                {/* mobile menu */}

                    <div className="mobile_menu text-center shadow-lg" id="mobileMenu">
                        <Link to="/" className="bgc"> 
                            <div className="home" id="home" onClick={this.home}>
                                <i className="fa fa-home"></i>
                            </div>
                        </Link>
                        <Link to="/employee" className="bgc">
                            <div className="employee" id="employee" onClick={this.employee}>
                                <i class="fas fa-user-tie"></i> 
                            </div>
                        </Link>
                        <Link to="/about_us" className="bgc">
                            <div className="about" id="about" onClick={this.about}>
                                <i class="fas fa-info-circle"></i>
                            </div>
                        </Link>
                        <Link to="/contact" className="bgc">
                            <div className="contact" id="contact" onClick={this.contact}>
                                <i class="fas fa-phone"></i> 
                            </div>
                        </Link>
                        <Link to="/login" className="bgc">
                            <div className="login" id="login" onClick={this.login}>
                                <i class="fas fa-user-circle"></i>
                            </div>
                        </Link>
                    </div>

            </>
        )
    }
}
export default Menu