import React from "react";
import './emplogin.css';
import { Link } from "react-router-dom";
import Axios from 'axios';

class EmpLogin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            phone: '',
            password: '',
            acceptTC: ''
        }
    }

    handle = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        // alert(e.target.value);
        if (e.target.value !== "") {
            document.getElementById("error" + e.target.name).innerHTML = "";
        }
        var normalTextRegEx = /^[a-zA-Z0-9]+([ a-zA-Z0-9])+/;
        var mobileNumberRegEx = /^[6789][0-9]{9}$/;
        var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        if (e.target.name === "name") {
            if (document.getElementById("name").value !== "") {
                if (document.getElementById("name").value.match(normalTextRegEx)) {
                    document.getElementById("errorname").innerHTML = "";
                }
                else {
                    document.getElementById("errorname").innerHTML = "Please enter a valid name.";
                }
            }
            else {
                document.getElementById("errorname").innerHTML = "This field is required. Please enter name.";
            }
        }

        if (e.target.name === "phone") {
            if (document.getElementById("phone").value !== "") {
                if (document.getElementById("phone").value.match(mobileNumberRegEx)) {
                    document.getElementById("errorphone").innerHTML = "";
                }
                else {
                    document.getElementById("errorphone").innerHTML = "Please enter a valid 10 digit number.";
                }
            }
            else {
                document.getElementById("errorphone").innerHTML = "This field is required. Please enter number.";
            }
        }

        if (e.target.name === "email") {
            if (document.getElementById("email").value !== "") {
                if (document.getElementById("email").value.match(emailRegEx)) {
                    document.getElementById("erroremail").innerHTML = "";
                }
                else {
                    document.getElementById("erroremail").innerHTML = "Please enter a valid email.";
                }
            }
            else {
                document.getElementById("erroremail").innerHTML = "This field is required. Please enter email.";
            }
        }

        if (e.target.name === "password") {
            if (document.getElementById("password").value !== "") {
                if (document.getElementById("password").value.length >= 5) {
                    document.getElementById("errorpassword").innerHTML = "";
                }
                else {
                    document.getElementById("errorpassword").innerHTML = "Please enter at least 5 digit password.";
                }
            }
            else {
                document.getElementById("errorpassword").innerHTML = "This field is required. Please enter password.";
            }
        }


    }

    acceptTC = (e) => {
        var tc;
        if (document.getElementById("acceptTC").checked === true) {
            tc = "Yes";
            document.getElementById("submit").disabled = false;
            this.setState({
                acceptTC: tc
            })
            document.getElementById("erroracceptTC").innerHTML = "";
        }
        else if (document.getElementById("acceptTC").checked === false) {
            tc = "No";
            document.getElementById("submit").disabled = true;
            this.setState({
                acceptTC: tc
            })
            document.getElementById("erroracceptTC").innerHTML = "This field is required. Please select.";
        }
    }

    submit = (e) => {

        e.preventDefault();

        var normalTextRegEx = /^[a-zA-Z0-9]+([ a-zA-Z0-9])+/;
        var mobileNumberRegEx = /^[6789][0-9]{9}$/;
        var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        var check = 0;

        if (document.getElementById("name").value !== "") {
            if (document.getElementById("name").value.match(normalTextRegEx)) {
                document.getElementById("errorname").innerHTML = "";
            }
            else {
                check++;
                document.getElementById("errorname").innerHTML = "Please enter a valid name.";
            }
        }
        else {
            check++;
            document.getElementById("errorname").innerHTML = "This field is required. Please enter name.";
        }


        if (document.getElementById("phone").value !== "") {
            if (document.getElementById("phone").value.match(mobileNumberRegEx)) {
                document.getElementById("errorphone").innerHTML = "";
            }
            else {
                check++;
                document.getElementById("errorphone").innerHTML = "Please enter a valid 10 digit number.";
            }
        }
        else {
            check++;
            document.getElementById("errorphone").innerHTML = "This field is required. Please enter number.";
        }


        if (document.getElementById("email").value !== "") {
            if (document.getElementById("email").value.match(emailRegEx)) {
                document.getElementById("erroremail").innerHTML = "";
            }
            else {
                check++;
                document.getElementById("erroremail").innerHTML = "Please enter a valid email.";
            }
        }
        else {
            check++;
            document.getElementById("erroremail").innerHTML = "This field is required. Please enter email.";
        }


        if (document.getElementById("password").value !== "") {
            if (document.getElementById("password").value.length >= 5) {
                document.getElementById("errorpassword").innerHTML = "";
            }
            else {
                check++;
                document.getElementById("errorpassword").innerHTML = "Please enter at least 5 digit password.";
            }
        }
        else {
            check++;
            document.getElementById("errorpassword").innerHTML = "This field is required. Please enter password.";
        }

        var tc;
        if (document.getElementById("acceptTC").checked === true) {
            tc = "Yes";
            document.getElementById("erroracceptTC").innerHTML = "";
            document.getElementById("submit").disabled = false;
            this.setState({
                acceptTC: tc
            })
        }
        else if (document.getElementById("acceptTC").checked === false) {
            tc = "No";
            document.getElementById("submit").disabled = true;
            this.setState({
                acceptTC: tc
            })
            document.getElementById("erroracceptTC").innerHTML = "This field is required. Please select.";
        }

        if (check === 0) {
            // eslint-disable-next-line new-parens
            let formData = new FormData();
            formData.append('name', this.state.name);
            formData.append('email', this.state.email);
            formData.append('phone', this.state.phone);
            formData.append('password', this.state.password);
            // console.log(obj)
            Axios.post(
              "https://digitizedresume.com/api/company/insert.php",
              formData
            ).then((res) => {
              if(res.data === 1){
                document.getElementById("submit").disabled = true;
                document.getElementById("companyRegistrationForm").reset();
                document.getElementById("alert").style.display = "block";
                setTimeout(function () {
                  document.getElementById("alert").style.display = "none";
                  window.location.href = "https://company.digitizedresume.com/";
                }, 3000);
              }
              else if(res.data === 0){
                document.getElementById("alert2").style.display = "block";
                setTimeout(function () {
                    document.getElementById("alert2").style.display = "none";
                }, 3000);
              }
            });
        }
    }

    render() {
        return (
            <>
                <div id="alert">
                    <p id="alertMessage">Account Created Successfully! <b>Redirecting to login...</b></p>
                </div>
                <div id="alert2">
                    <p id="alertMessage">This email is already registered!</p>
                </div>
                <div className="container-fluid emplyeer_section">
                    <div className="row mt-4">
                        <div className="col-md-4"> </div>

                        <div className="col-md-4 emplyeer_page shadow">
                            <h5>Employer - Sign Up to <span className="digibot"> Digitized</span> Resume</h5>

                            <form className="text-center mt-4" id="companyRegistrationForm" onSubmit={this.submit}>
                                <input type="text" onChange={this.handle} name="name" id="name" style={{textTransform: 'capitalize'}} placeholder="Enter company name" />
                                <p className="error" id="errorname"></p>
                                <input type="number" onChange={this.handle} name="phone" id="phone" placeholder="Enter number" />
                                <p className="error" id="errorphone"></p>
                                <input type="email" onChange={this.handle} name="email" id="email" placeholder="Enter email" />
                                <p className="error" id="erroremail"></p>
                                <input type="password" onChange={this.handle} name="password" id="password" placeholder="Enter password" />
                                <p className="error" id="errorpassword"></p>

                                <div class="form-check mt-1">
                                    <input type="checkbox" id="acceptTC" onClick={this.acceptTC} name="acceptTC" className="" value="" />
                                    <span className="remember">I accept the Terms of Services</span>
                                    <p className="error" id="erroracceptTC"></p>
                                </div>

                                <button class="btn btn-style mt-3" name="submit" id="submit">SIGN UP </button>

                                <p class="already">Already have an account? <a href="https://company.digitizedresume.com">Sign in Now</a></p>

                                <div className="social-media mt-4">
                                    <a href="javascript:void(0)" className="fb"><span class="fab fa-facebook" aria-hidden="true"></span> Login with facebok</a>
                                    <a href="javascript:void(0)" className="tw"><span class="fab fa-google" aria-hidden="true"></span> Login with google</a>
                                </div>

                            </form>

                        </div>

                        <div className="col-md-4"> </div>
                    </div>
                </div>
            </>
        )
    }
}
export default EmpLogin;