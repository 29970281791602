import React from "react";
import './talent.css';
import { Link } from "react-router-dom";
import Designers from "./Designers.svg";
import Developers from "./developers.svg";
import Finance from "./Finance.svg";

class Talent extends React.Component{
    render(){
        return(
            <>
                 <div className="container-fluid talent-section">
                    <div className="row talent mb-5">
                       <div className="col-md-12">
                            <h1 className="talent_title" data-aos='fade-up' data-aos-duration="2000">Leverage World-Class Talent</h1>
                            <p className="talent_subtitle" data-aos='fade-up' data-aos-duration="2000">We are the largest,globally-distribute network of top business, design, and technology talent, <br></br> ready to tackle your most important initiatives.</p>
                       </div>
                        {/* talent card */}
                        <div className="col-md-1"></div>

                        <div className="finance_card col-md-3" data-aos='fade-up' data-aos-duration="2000">
                            <img src={Finance} className="img-fluid" alt="" data-aos="flip-left" data-aos-duration="2000" />
                            <h4>Finance Expert</h4>
                            <p>Expert in financial modeling & valuation, startup funding, interim CFO work, and market sizing.</p>
                            <h6>8K Online every day</h6>
                            <h6>2K Project done by Finance Expert</h6>

                            <Link to="/create_resume" className="registertext">
                                <div className="finance_button">
                                    Register now   
                                </div>
                            </Link> 
                        </div>
                        <div className="Developers_card col-md-3" data-aos='fade-up' data-aos-duration="2000">
                            <img src={Developers} className="img-fluid" alt="" data-aos="flip-left" data-aos-duration="2000" />
                            <h4>Developers</h4>
                            <p>Seasoned software engineers, coders,and architechs with expertise accross hundreds of technologies.</p>
                            <h6>12.4K Online every day</h6>
                            <h6>4.3K Project done by developers</h6>

                            <Link to="/create_resume" className="registertext">
                                <div className="finance_button">
                                    Register now   
                                </div>
                            </Link> 
                            
                        </div>
                        <div className="Designers_card col-md-3" data-aos='fade-up' data-aos-duration="2000">
                            <img src={Designers} className="img-fluid" alt="" data-aos="flip-left" data-aos-duration="2000" />
                            <h4>Designers</h4>
                            <p>Expert UI, UX, Visual, and interaction designers as well as a wide range of illustrators, animators, and more.</p>
                            <h6>14K Online every day</h6>
                            <h6>6.2K Project done by Designers</h6>

                            <Link to="/create_resume" className="registertext">
                                <div className="finance_button">
                                    Register now   
                                </div>
                            </Link> 
                            
                        </div>

                        {/* <div className="Developers_card col-md-3">

                        </div>

                        <div className="Designers_card col-md-3">

                        </div> */}

                        <div className="col-md-1"></div>
                            <Link to="/"> 
                                <div className="prev_button shadow">
                                <i className="fa fa-angle-up"></i>
                                </div>
                            </Link>
                            <Link to="/footer">
                                <div className="next_button shadow">
                                    <i className="fa fa-angle-down"></i> 
                                </div>
                            </Link>

                    </div>
                </div>
            </>
        )
    }
}
export default Talent