import React from "react";
import './whyUs.css';
import { Link } from "react-router-dom";

class WhyUs extends React.Component{
    render(){
        return(
            <div className="container-fluid why_us_section">
                <div className="row why_us">
                   <div className="col-md-2"></div>

                   <div className="col-md-8 my-5 pb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="whyus_title" data-aos="fade-up" data-aos-duration="2000">Why Organization <br></br>Choose <span className="rotate_me">Us</span> <img src="../../assets/image/arrow3.svg" alt="" /> </h3>
                                <p className="whyus_subtitle" data-aos="fade-up" data-aos-duration="3000">Discover the many ways in which our clients have <br></br> embarce the benifit of the toptal network</p>


                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item" data-aos="flip-left" data-aos-duration="3000">
                                        <h2 class="accordion-header shadow" id="headingFour">
                                        <span class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                            <span>Why Choose Us</span>
                                        </span>
                                        </h2>
                                        <div id="collapseFour" class="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div class="accordion-body shadow">
                                            <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element.
                                        </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div class="accordion accordion-flush"  id="accordionFlushExample">
                                    <div class="accordion-item" data-aos="flip-up" data-aos-duration="3000">
                                        <h2 class="accordion-header shadow" id="headingOne">
                                        <span class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <span>Why Choose Us</span>
                                        </span>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body shadow">
                                            <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element.
                                        </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header shadow" id="headingTwo">
                                        <span class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <span>Why Choose Us</span>
                                        </span>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body shadow">
                                            <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element.
                                        </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header shadow" id="headingThree">
                                        <span class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <span>Why Choose Us</span>
                                        </span>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body shadow">
                                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element.
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </div>

                   <div className="col-md-2"></div>

                    {/* next & previous button */}
                        <Link to="/employee">
                            <div className="prev_button shadow">
                                <i className="fa fa-angle-up"></i> 
                            </div>
                        </Link>
                        
                        <Link to="/testimonial">
                            <div className="next_button shadow">
                                <i className="fa fa-angle-down"></i> 
                            </div>
                        </Link>
                        
                     {/* next & previous button */} 


                </div>
            </div>
        )
    }
}
export default WhyUs