import React from "react";
import './employee.css';
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';

import Designers from "./Designers.svg";
import Developer from "./developer.png";
import Finance from "./Finance.jpg";
import ProductMan from "./product-manager.png";
import ProjectMan from "./project-manager.png";


const options = {
    margin: 5,
    nav: true,
    navText: ["<div class='nav-button owl-prev'> <span>‹</span> </div>", "<div class='nav-button owl-next'>›</div>"],
        responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 4
        }
    }
};

class Employee extends React.Component{

    

    render(){
        return(
            <>
                <div className="container-fluid employee_section">
                    <div className="row employe">
                       <div className="col-md-12">
                            <h1 className="employe_title" data-aos='fade-up' data-aos-duration="2000">Meet Talent in Our Network</h1>
                            <p className="employe_subtitle" data-aos='fade-up' data-aos-duration="2000">We are the largest,globally-distribute network of top business, design, and technology talent, <br></br> ready to tackle your most important initiatives.</p>
                       </div>

                       <div className="col-md-1"></div>

                            <div className="col-md-10 mt-5 mb-5 talent_card">
                                <div class="row">

                                <OwlCarousel className="owl-theme" loop nav {...options}>
                                    <div class="item" data-aos='flip-right' data-aos-duration="2000">
                                        <div class="col-md-12 Developer_card">
                                            <div class="card p-3">
                                                <div class="card-body">
                                                <img src={Developer} className="img-fluid" alt="" />
                                                <h6>Developers</h6>
                                                <p>345 Professionals</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item" data-aos='flip-left' data-aos-duration="2000">
                                        <div class="col-md-12 Developer_card">
                                            <div class="card p-3">
                                                <div class="card-body">
                                                <img src={Designers} className="img-fluid" alt="" />
                                                <h6>Designers</h6>
                                                <p>345 Professionals</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item" data-aos='flip-right' data-aos-duration="2000">
                                        <div class="col-md-12 Developer_card">
                                            <div class="card p-3">
                                                <div class="card-body">
                                                <img src={Finance} className="img-fluid" alt="" />
                                                <h6>Finance Expert</h6>
                                                <p>345 Professionals</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item" data-aos='flip-left' data-aos-duration="2000">
                                        <div class="col-md-12 Developer_card">
                                            <div class="card p-3">
                                                <div class="card-body">
                                                <img src={ProjectMan} className="img-fluid" alt="" />
                                                <h6>Project Manager</h6>
                                                <p>345 Professionals</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item ">
                                        <div class="col-md-12 Developer_card">
                                            <div class="card p-3">
                                                <div class="card-body">
                                                <img src={ProductMan} className="img-fluid" alt="" />
                                                <h6>Product Manager</h6>
                                                <p>345 Professionals</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>                                    
                                </div>
                            </div>
                
                        <div className="col-md-1"></div>


                        <div className="col-md-12 pb-5">
                            <Link to="/create_resume" className="now">
                                <div className="register_button">
                                    Register Now 
                                </div>
                            </Link>
                        </div>

                    {/* next & previous button */}

                    <Link to="/why_us">
                        <div className="next_button shadow">
                             <i className="fa fa-angle-down"></i> 
                        </div>
                    </Link> 
                     {/* next & previous button */}

                    </div>
                </div>
            </>
        )
    }
}
export default Employee