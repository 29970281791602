import React from "react"
import { Link } from "react-router-dom";
import './loginPage.css';

class PhoneLoginPage extends React.Component {
    render() {
        return (
            <>
                <div className="container phoneLoginPage" style={{ marginTop: '30vh' }}>
                    <div className="row">
                        <div className="col-md-4">
                            <h5><span className="digibot"> Choose Login </span></h5>
                        </div>
                        <div className="col-md-4 my-4 mobile_login" >
                            <div className="loginPageButton">
                                <a href="https://dashboard.digitizedresume.com/" className="log">Login</a>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="loginPageButton">
                                <a href="https://company.digitizedresume.com/" className="log"> Employer Login </a>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default PhoneLoginPage;