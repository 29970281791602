import React from "react";
import './contact.css';
import line from "./line.svg";
import contactimg from "./About-us_Velvot.png";
import Axios from 'axios';

class Contact extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      subject: '',
      email: '',
      message: ''
    }
  }

  handle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // alert(e.target.value);
    if (e.target.value !== "") {
      document.getElementById("error" + e.target.name).innerHTML = "";
    }
    var normalTextRegEx = /^[a-zA-Z0-9]+([ a-zA-Z0-9])+/;
    var mobileNumberRegEx = /^[6789][0-9]{9}$/;
    var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


    if (e.target.name === "name") {
      if (document.getElementById("name").value !== "") {
        if (document.getElementById("name").value.match(normalTextRegEx)) {
          document.getElementById("errorname").innerHTML = "";
        }
        else {
          document.getElementById("errorname").innerHTML = "Please enter a valid name.";
        }
      }
      else {
        document.getElementById("errorname").innerHTML = "This field is required. Please enter name.";
      }
    }

    if (e.target.name === "subject") {
      if (document.getElementById("subject").value !== "") {
        if (document.getElementById("subject").value.match(normalTextRegEx)) {
          document.getElementById("errorsubject").innerHTML = "";
        }
        else {
          document.getElementById("errorsubject").innerHTML = "Please enter a valid subject.";
        }
      }
      else {
        document.getElementById("errorsubject").innerHTML = "This field is required. Please enter subject.";
      }
    }

    if (e.target.name === "email") {
      if (document.getElementById("email").value !== "") {
        if (document.getElementById("email").value.match(emailRegEx)) {
          document.getElementById("erroremail").innerHTML = "";
        }
        else {
          document.getElementById("erroremail").innerHTML = "Please enter a valid email.";
        }
      }
      else {
        document.getElementById("erroremail").innerHTML = "This field is required. Please enter email.";
      }
    }

    if (e.target.name === "message") {
      if (document.getElementById("message").value !== "") {
        if (document.getElementById("message").value.match(normalTextRegEx)) {
          document.getElementById("errormessage").innerHTML = "";
        }
        else {
          document.getElementById("errormessage").innerHTML = "Please enter valid message";
        }
      }
      else {
        document.getElementById("errormessage").innerHTML = "This field is required. Please enter message.";
      }
    }


  }

  submit = (e) => {

    e.preventDefault();

    var normalTextRegEx = /^[a-zA-Z0-9]+([ a-zA-Z0-9])+/;
    var mobileNumberRegEx = /^[6789][0-9]{9}$/;
    var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var check = 0;

    if (document.getElementById("name").value !== "") {
      if (document.getElementById("name").value.match(normalTextRegEx)) {
        document.getElementById("errorname").innerHTML = "";
      }
      else {
        check++;
        document.getElementById("errorname").innerHTML = "Please enter a valid name.";
      }
    }
    else {
      check++;
      document.getElementById("errorname").innerHTML = "This field is required. Please enter name.";
    }


    if (document.getElementById("subject").value !== "") {
      if (document.getElementById("subject").value.match(normalTextRegEx)) {
        document.getElementById("errorsubject").innerHTML = "";
      }
      else {
        document.getElementById("errorsubject").innerHTML = "Please enter a valid subject.";
      }
    }
    else {
      document.getElementById("errorsubject").innerHTML = "This field is required. Please enter subject.";
    }


    if (document.getElementById("email").value !== "") {
      if (document.getElementById("email").value.match(emailRegEx)) {
        document.getElementById("erroremail").innerHTML = "";
      }
      else {
        check++;
        document.getElementById("erroremail").innerHTML = "Please enter a valid email.";
      }
    }
    else {
      check++;
      document.getElementById("erroremail").innerHTML = "This field is required. Please enter email.";
    }


    if (document.getElementById("message").value !== "") {
      if (document.getElementById("message").value.match(normalTextRegEx)) {
        document.getElementById("errormessage").innerHTML = "";
      }
      else {
        document.getElementById("errormessage").innerHTML = "Please enter valid message";
      }
    }
    else {
      document.getElementById("errormessage").innerHTML = "This field is required. Please enter message.";
    }

    if (check === 0) {
      // eslint-disable-next-line new-parens
      let formData = new FormData();
      formData.append('name', this.state.name);
      formData.append('email', this.state.email);
      formData.append('subject', this.state.subject);
      formData.append('message', this.state.message);
      // console.log(obj)
      Axios.post('https://digitizedresume.com/api/enquiry.php', formData)
        .then((res) => {
          // alert(res.data);
          document.getElementById("enquiryForm").reset();
          document.getElementById("alert").style.display = "block";
          setTimeout(function () {
            document.getElementById("alert").style.display = "none";
          }, 4000);
        });
    }
  }

  render() {
    return (
      <>
        <div id="alert">
          <p id="alertMessage">Feedback Sent Successfully!</p>
        </div>
        <div className="container-fluid contact_section">
          <div className="row mt-5">
            <div className="col-md-1"></div>
            <div
              className="col-md-5 pt-5 contact_information"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div class="bids_heading">
                <h2>Contact Information</h2>
                <h6>
                  Opening a ticket is the fastest and most efficient method of
                  support.
                </h6>
                <div class="line-shape1">
                  <img src={line} alt="" />
                </div>
              </div>

              <ul class="cinfo10">
                <li>
                  <p>
                    <span>
                      <i class="fas fa-map-marker-alt"></i>
                    </span>{" "}
                    #147,Boregowda complex,Hebbal 1st stage,Basavanagudi,
                    Metagalli,Mysore- 570017
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <i class="fas fa-envelope"></i>
                    </span>
                    <a class="__cf_email__">info@gmail.com</a>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <i class="fas fa-mobile"></i>
                    </span>{" "}
                    +91 123456789
                  </p>
                </li>
              </ul>
            </div>
            <div
              className="col-md-5"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <img src={contactimg} className="img-fluid" />
            </div>

            {/* contact from section */}

            <div
              className="col-md-6 offset-md-3 mt-5"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="contact_form mb-5">
                <div class="main-heading">
                  <h2>Reached Us</h2>
                  <div class="line-shape1">
                    <img src={line} alt="" />
                  </div>
                </div>

                <form id="enquiryForm" onSubmit={this.submit}>
                  <div class="row">
                    <div class=" col-md-6">
                      <div class="form-group">
                        <label class="label">Name*</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          onChange={this.handle}
                          class="job-input"
                          placeholder="Enter Name"
                        />
                        <p className="error" id="errorname"></p>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="label">Email*</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          onChange={this.handle}
                          class="job-input"
                          placeholder="Enter Email"
                        />
                        <p className="error" id="erroremail"></p>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="label">Subject*</label>
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          onChange={this.handle}
                          class="job-input"
                          placeholder="Enter subject"
                        />
                        <p className="error" id="errorsubject"></p>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="label">Message*</label>
                        <textarea
                          class="note-input"
                          name="message"
                          id="message"
                          onChange={this.handle}
                          placeholder="Text Message"
                        ></textarea>
                        <p className="error" id="errormessage"></p>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <button class="withdraw_btn" type="submit">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Contact;