import React from "react";
import './testimonial.css';
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';

import Profile1 from "./profile1.jpg";
// import WhiteLogo from "./whitelogo.svg";

const options = {
    margin: 15,
    nav: true,
    navText: ["<div class='nav-button owl-prev'> <span>‹</span> </div>", "<div class='nav-button owl-next'>›</div>"],
    responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 2
        }
    }
};

class Testimonial extends React.Component{

  
    render(){
        return (
          <div className="container-fluid Testimonial-section">
            <div className="row testimonial">
              {/* testimonial upper section */}

              <div className="col-md-2"></div>

              <div className="col-md-6 col-12 testimonial_content">
                <h2
                  className="testimonial_title pt-4"
                  data-aos="zoom-in-down"
                  data-aos-duration="2000"
                >
                  What Our Customer <br></br> are saying
                </h2>
              </div>
              <div className="col-md-2 testimonial_button">
                {/* <div className="swipe-left "> <i className="fa fa-arrow-left"></i> </div>
                        <div className="swipe-right"> <i className="fa fa-arrow-right"></i></div> */}
              </div>
              <div className="col-md-2"></div>

              {/* testimonial feedback section */}

              <div className="col-md-2"></div>

              <div className="col-md-8 mt-5 mb-5">
                <div className="row">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    nav
                    {...options}
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div
                      class="item"
                      data-aos="zoom-in-down"
                      data-aos-duration="2000"
                    >
                      <div className="col-md-11 feedback_section">
                        <span className="feedback_content">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Magnam unde nostrum eveniet, laudantium ex sed!
                          Totam, ipsam. Mollitia maxime, cumque itaque obcaecati
                          voluptatem, in nulla,
                        </span>

                        <div className="row mt-4">
                          <div className="col-md-3 testimonial_image">
                            <img src={Profile1} className="img-fluid" alt="" />
                          </div>
                          <div className="col-md-9">
                            <h6>Zac carman , CEO</h6>
                            <span className="cname">Consumer Affairs</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="item col-md-11"
                      data-aos="zoom-in-down"
                      data-aos-duration="2000"
                    >
                      <div className=" feedback_section">
                        <span className="feedback_content">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Magnam unde nostrum eveniet, laudantium ex sed!
                          Totam, ipsam. Mollitia maxime, cumque itaque obcaecati
                          voluptatem,
                        </span>

                        <div className="row mt-4">
                          <div className="col-md-3 testimonial_image">
                            <img src={Profile1} className="img-fluid" alt="" />
                          </div>
                          <div className="col-md-9">
                            <h6>Zac carman , CEO</h6>
                            <span className="cname">Consumer Affairs</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="item col-md-11">
                      <div className=" feedback_section">
                        <span className="feedback_content">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Magnam unde nostrum eveniet, laudantium ex sed!
                          Totam, ipsam. Mollitia maxime, cumque itaque obcaecati
                          voluptatem,
                        </span>

                        <div className="row mt-4">
                          <div className="col-md-3 testimonial_image">
                            <img src={Profile1} className="img-fluid" alt="" />
                          </div>
                          <div className="col-md-9">
                            <h6>Zac carman , CEO</h6>
                            <span className="cname">Consumer Affairs</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="item col-md-11">
                      <div className=" feedback_section">
                        <span className="feedback_content">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Magnam unde nostrum eveniet, laudantium ex sed!
                          Totam, ipsam. Mollitia maxime, cumque itaque obcaecati
                          voluptatem,
                        </span>

                        <div className="row mt-4">
                          <div className="col-md-3 testimonial_image">
                            <img src={Profile1} className="img-fluid" alt="" />
                          </div>
                          <div className="col-md-9">
                            <h6>Zac carman , CEO</h6>
                            <span className="cname">Consumer Affairs</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>

              <div className="col-md-2"></div>
              {/* testimonial feedback section end */}

             
              {/* next_button */}

              <Link to="/employee">
                <div className="next_button shadow">
                    {" "}
                    <i className="fa fa-angle-up"></i>{" "}
                </div>
              </Link>
            </div>
          </div>
        );
    }
}
export default Testimonial