import React from "react";
import './about.css';
import { Link } from "react-router-dom";
import aboutimg from "./about1.png";
import OwlCarousel from 'react-owl-carousel';
import profile from "./profile.jpg";
import profile1 from "./profile1.jpg";
import profile2 from "./profile2.jpg";
import profile3 from "./profile3.jpg";
import Footer from "../footer/footer";



const options = {
    margin: 15,
    nav: true,
    navText: ["<div class='nav-button owl-prev'> <span>‹</span> </div>", "<div class='nav-button owl-next'>›</div>"],
        responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
    }
};




class AboutUS extends React.Component{
    render(){
        return (
          <div className="container-fluid about-section">
            <div className="row">
              <div className="col-md-12">
                <h1
                  className="about_title"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  About Us
                </h1>
                <p
                  className="about_subtitle"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  We are the largest,globally-distribute network of top
                  business, design, and technology talent, <br></br> ready to
                  tackle your most important initiatives.
                </p>
              </div>

              <div className="col-md-1"></div>
              <div className="col-md-5">
                <img
                  src={aboutimg}
                  className="img-fluid"
                  alt=""
                  data-aos="fade-up"
                  data-aos-duration="2000"
                />
              </div>
              <div
                className="col-md-5 pt-5 about_content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h3>About Us</h3>
                <p className="abt_cont">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer quis maximus ante. Morbi iaculis scelerisque mauris id
                  molestie. Mauris aliquet mi ut neque pharetra convallis.
                  Aliquam vitae tincidunt neque. Aliquam ut felis eleifend felis
                  consectetur finibus in vitae turpis. Donec maximus ullamcorper
                  ante, feugiat molestie justo faucibus dignissim. Nunc dapibus
                  pretium odio vitae elementum.
                </p>
                <ul type="circle">
                  <li>
                    <span className="fa fa-chevron-right"></span>
                    consectetur adipiscing elit Integer quis maximus ante.
                  </li>
                  <li>
                    <span className="fa fa-chevron-right"></span>
                    consectetur adipiscing elit Integer quis maximus ante.
                  </li>
                  <li>
                    <span className="fa fa-chevron-right"></span>
                    consectetur adipiscing elit Integer quis maximus ante.
                  </li>
                  <li>
                    <span className="fa fa-chevron-right"></span>
                    consectetur adipiscing elit Integer quis maximus ante.
                  </li>
                  <li>
                    <span className="fa fa-chevron-right"></span>
                    consectetur adipiscing elit Integer quis maximus ante.
                  </li>
                </ul>
              </div>

              {/* our team  */}

              <div className="col-md-12 mt-4">
                <h1 className="about_title">Our Team</h1>
                <p className="about_subtitle">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer quis maximus ante. <br></br> Morbi iaculis scelerisque
                  mauris id molestie.
                </p>
              </div>

              <div className="col-md-1"></div>

              <div className="col-md-9 mt-5 mb-5 team_card">
                <div class="row">
                  <OwlCarousel className="owl-theme" loop nav {...options}>
                    <div
                      class="item"
                      data-aos="flip-right"
                      data-aos-duration="3000"
                    >
                      <div class="col-md-12 card_team">
                        <div class="">
                          <div class="card-body">
                            <img src={profile} className="img-fluid" alt="" />
                            <div className="bottom_section text-center bg-white">
                              <h6>Gyen Miller</h6>
                              <span>Finance Manager</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="item"
                      data-aos="flip-right"
                      data-aos-duration="3000"
                    >
                      <div class="col-md-12 card_team">
                        <div class="">
                          <div class="card-body">
                            <img src={profile1} className="img-fluid" alt="" />
                            <div className="bottom_section bg-white">
                              <h6>Allen mic</h6>
                              <span>Accountant</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="item col-md-12"
                      data-aos="flip-right"
                      data-aos-duration="3000"
                    >
                      <div class=" card_team">
                        <div class="">
                          <div class="card-body">
                            <img src={profile2} className="img-fluid" alt="" />
                            <div className="bottom_section bg-white">
                              <h6>Joe holly</h6>
                              <span>Senior Developer</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="item col-md-12"
                      data-aos="flip-right"
                      data-aos-duration="3000"
                    >
                      <div class=" card_team">
                        <div class="">
                          <div class="card-body">
                            <img src={profile3} className="img-fluid" alt="" />
                            <div className="bottom_section bg-white">
                              <h6>James Smith</h6>
                              <span> Web Developer</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        );
    }
}
export default AboutUS