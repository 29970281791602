import React from "react";
import './footer.css';
import { NavLink, Link } from "react-router-dom";
import Hero3 from "./hero3.svg";
import WhiteLogo from "./whitelogo.svg";


class Footer extends React.Component{
    render(){
        return (
          <div className="container-fluid footer-section">
            <div className="row footer">
              <div className="col-md-5 footer_banner">
                <img
                  src={Hero3}
                  alt=""
                  data-aos="flip-left"
                  data-aos-duration="1000"
                  loading="lazy"
                />
              </div>
              <div className="col-md-7 col-12 footer_banner">
                <h1 data-aos="fade-up" data-aos-duration="1000">
                  Ready to get started ?
                </h1>
                <Link to="/employer-signup">
                  <div
                    className="hire shadow mt-4"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Hire Top Talent
                  </div>
                </Link>

                <Link to="/contact">
                  <div
                    className="contact mt-4"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    {" "}
                    Contact Us
                  </div>
                </Link>
              </div>
           


              {/* footer section  */}
              <div className="col-md-12 footer_section pb-5">
                <div className="row">
                  <div className="col-md-3 text-center">
                    <img src={WhiteLogo} alt="" />
                    <br></br>

                    <p className="about_company px-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                       sed do eiusmod tempor incididunt ut labore et dolore magna
                       aliqua.
                    </p>

                    {/* social media icon */}
                    <div className="social_media text-start">
                      <i class="fab fa-google"></i>
                      <i class="fab fa-twitter"></i>
                      <i class="fab fa-instagram"></i>
                      <i class="fab fa-linkedin"></i>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <h5>Most In-Demand Talent</h5>
                    <ul class="mt-4">
                      <Link to="/employee">
                        {" "}
                        <li>IOS Developers</li>
                      </Link>
                      <Link to="/employee">
                        {" "}
                        <li>Front-End Developers</li>
                      </Link>
                      <Link to="/employee">
                        {" "}
                        <li>UX Desiners</li>
                      </Link>
                      <Link to="/employee">
                        {" "}
                        <li>UI Designers</li>
                      </Link>
                      <Link to="/employee">
                        {" "}
                        <li>Financial Modeling Consultants</li>
                      </Link>
                      <Link to="/employee">
                        <li>Digital Project Managers</li>
                      </Link>
                    </ul>
                  </div>

                  <div className="col-md-3">
                    <h5>Freelancer Talent</h5>
                    <ul class="mt-4">  
                      <Link to="/employer-signup">
                        {" "}
                        <li>Freelance Developers</li>
                      </Link>
                      <Link to="/employer-signup">
                        {" "}
                        <li>Freelance Designers</li>
                      </Link>
                      <Link to="/employer-signup">
                        {" "}
                        <li>Freelance Finance Expert</li>
                      </Link>
                      <Link to="/employer-signup">
                        <li>Freelance Project Managers</li>
                      </Link>
                      <Link to="/employer-signup">
                        <li>Freelance Product Managers</li>
                      </Link>
                    </ul>
                  </div>

                  <div className="col-md-3">
                    <h5>Contact</h5>
                    <ul class="mt-4">
                      <Link to="/contact">
                        {" "}
                        <li>Contact</li>
                      </Link>

                     <Link to="/contact">
                        {" "}
                        <li>About</li>
                     </Link>

                      <Link to="/why_us">
                        {" "}
                        <li>FAQ</li>
                      </Link>
                     
                      <Link to="/privacy-policy" target="_blank">
                        <li>Privacy Policy</li>
                      </Link>
                      <Link to="/terms-of-condition" target="_blank">
                        <li>Terms & Condition</li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>

              <Link to="/">
                <div className="next_button shadow">
                    <i className="fa fa-angle-up"></i>
                </div>
              </Link>
            </div>
          </div>
        );
    }
}
export default Footer