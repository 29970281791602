import React from "react";
import './home1.css';
import { Link } from "react-router-dom";

import Arrow1 from "./Arrow1.svg";
import Arrow2 from "./Arrow2.svg";
import Ellipse from "./Ellipse.svg";
import Hero1 from "./hero1.svg";
import Hero2 from "./hero2.svg";




class Home1 extends React.Component{
    render(){
        return (
          <>
            <div className="container-fluid home1">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1 className="heading_title text-dark"> Create Your Digital Resume</h1>
                  <img
                    src={Arrow1}
                    className="arrow_img"
                    alt=""
                  />
                </div>

                <div className="col-md-4 text-end hero_img1">
                  <img
                    src={Hero1}
                    className="img-fluid"
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  />
                </div>

                <div
                  className="col-md-4 text-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <p className="heading_subtitle">
                    Digitized Resume is an exclusive network of the top
                    freelance software developers, designers, finance experts,
                    product managers, and project managers in the world.
                  </p>
                  <Link to="/create_resume">
                    {" "}
                    <div
                      className="create_resume shadow mt-5"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      CREATE RESUME
                    </div>{" "}
                  </Link>
                  <img
                    src={Arrow2}
                    className="arrow_img2"
                    alt=""
                  />
                  <br></br>
                  <span className="register_count pt-4 text-dark">
                    {" "}
                    4.2k Register Everyday{" "}
                  </span>{" "}
                  <br></br>
                  <img
                    src={Ellipse}
                    className="ellipse"
                    alt=""
                  />
                </div>

                <div className="col-md-4">
                  <img
                    src={Hero2}
                    className="img-fluid hero_img2"
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  />
                </div>

                {/* next_button */}

                <Link to="/talent"> 
                    <div className="next_button shadow">
                      <i className="fa fa-angle-down"></i>
                    </div>
                </Link>  
             

                {/* <!-- Modal --> */}
                {/* <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{zIndex:'9999999'}}>
                                <div class="modal-dialog modal-xl modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <button class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className="row domain_search_section p-3">
                                                <div className="col-md-3 text-center">
                                                    <h6>Create Your Domain Here.... </h6>
                                                </div>
                                                <div className="col-md-6">
                                                    <form>
                                                        <input type="text" className="domain_search" placeholder="Search your domain or name to create and show your profile..." />
                                                    </form>
                                                </div>
                                                <div className="col-md-2">
                                                    <button class="srch-btn" type="submit">Check Availability</button>
                                                </div>
                                            </div>
                                        </div>
                                   
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
          </>
        );
    }
}
export default Home1