import React from "react";
import './login.css';
import { Link } from "react-router-dom";
import Axios from 'axios';

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        }
    }

    handle = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        // alert(e.target.value);
        if (e.target.value !== "") {
            document.getElementById("error" + e.target.name).innerHTML = "";
        }
        var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (e.target.name === "email") {
            if (document.getElementById("email").value !== "") {
                if (document.getElementById("email").value.match(emailRegEx)) {
                    document.getElementById("erroremail").innerHTML = "";
                }
                else {
                    document.getElementById("erroremail").innerHTML = "Please enter a valid email.";
                }
            }
            else {
                document.getElementById("erroremail").innerHTML = "This field is required. Please enter email.";
            }
        }

        if (e.target.name === "password") {
            if (document.getElementById("password").value !== "") {
                if (document.getElementById("password").value.length > 5) {
                    document.getElementById("errorpassword").innerHTML = "";
                }
                else {
                    document.getElementById("errorpassword").innerHTML = "Please enter at least 5 digit password.";
                }
            }
            else {
                document.getElementById("errorpassword").innerHTML = "This field is required. Please enter password.";
            }
        }

    }

    submit = (e) => {

        e.preventDefault();

        var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        var check = 0;

        if (document.getElementById("email").value !== "") {
            if (document.getElementById("email").value.match(emailRegEx)) {
                document.getElementById("erroremail").innerHTML = "";
            }
            else {
                check++;
                document.getElementById("erroremail").innerHTML = "Please enter a valid email.";
            }
        }
        else {
            check++;
            document.getElementById("erroremail").innerHTML = "This field is required. Please enter email.";
        }


        if (document.getElementById("password").value !== "") {
            if (document.getElementById("password").value.length > 5) {
                document.getElementById("errorpassword").innerHTML = "";
            }
            else {
                check++;
                document.getElementById("errorpassword").innerHTML = "Please enter at least 5 digit password.";
            }
        }
        else {
            check++;
            document.getElementById("errorpassword").innerHTML = "This field is required. Please enter password.";
        }

        if (check === 0) {
            let formData = new FormData();
            formData.append('email', this.state.email);
            formData.append('password', this.state.password);
            // console.log(obj)
            Axios.post('https://digitizedresume.com/api/company/login.php', formData)
                .then((res) => {
                    // alert(res.data);
                    if (res.data === 0) {
                        document.getElementById("alert").style.display = "none";
                        document.getElementById("alert2").style.display = "block";
                        setTimeout(function () {
                            document.getElementById("alert").style.display = "none";
                            document.getElementById("alert2").style.display = "none";
                        }, 4000);
                    }
                    else {
                        // document.getElementById("companyLoginForm").reset();
                        // document.getElementById("alert").style.display = "block";
                        // document.getElementById("alert2").style.display = "none";
                        // setTimeout(function () {
                        //     document.getElementById("alert").style.display = "none";
                        //     document.getElementById("alert2").style.display = "none";
                        // }, 4000);
                        // alert(res.data[0].id);
                        localStorage.setItem("cId",res.data[0].id);
                        setTimeout(function () {
                            window.location.href="https://digitizedresume.com/company/";
                        }, 1000);
                        
                    }
                });
        }
    }

    render() {
        return (
            <>
                <div id="alert2">
                    <p id="alertMessage">Email or password is incorrect!</p>
                </div>
                <div id="alert">
                    <p id="alertMessage">We're working on it and will be live soon!</p>
                </div>
                <div className="container-fluid login_section">
                    <div className="row mt-5">


                        {/* multiple section login */}

                        <div className="col-md-12 mb-4 mobile_login " >
                            <div className="log_employee">
                                <Link to="/employee-login" className="log"> Employee Login </Link>
                            </div>
                        </div>


                        {/* login section */}

                        <div className="col-md-4"> </div>

                        <div className="col-md-4 mb-5 login_page shadow">
                            <h5>Employer Login</h5>

                            <form className="text-center mt-4" id="companyLoginForm" onSubmit={this.submit}>

                                <input type="email" onChange={this.handle} name="email" id="email" placeholder="Enter email" />
                                <p className="error" id="erroremail"></p>
                                <input type="password" onChange={this.handle} name="password" id="password" placeholder="Enter password" />
                                <p className="error" id="errorpassword"></p>

                                <div class="form-check mt-1">
                                    <input type="checkbox" className="pt-1" value="" />
                                    <span className="remember">Remember me </span>
                                </div>

                                <button class="btn btn-style mt-3" type="submit">Login </button>

                                <p class="already">Don't have an account? <Link to="/employer-signup">Sign up</Link></p>

                                <div className="social-media mt-3">
                                    <a href="javascript:void(0)" className="fb"><span class="fab fa-facebook" aria-hidden="true"></span> Login with facebok</a>
                                    <a href="javascript:void(0)" className="tw"><span class="fab fa-google" aria-hidden="true"></span> Login with google</a>
                                </div>
                            </form>

                        </div>

                        <div className="col-md-4"> </div>

                    </div>
                </div>
            </>
        )
    }
}
export default Login