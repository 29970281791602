import React from "react";
import "./cv.css";
import Axios from 'axios';
import ReactPlayer from "react-player";
import OwlCarousel from 'react-owl-carousel';

const options = {
    margin: 15,
    nav: true,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        700: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
}

export class LightThemeCV extends React.Component {
    constructor() {
        super();
        this.state = {
            page: "home",
            totalPage: 7,
            currentPageNo: 0,
            allPage: ["home", "about-me", "resume", "portfolio", "video", "certificate", "project", "contact"],
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f1f1f1";
    }

    switchPage = (val, pgno) => {
        this.state.allPage.forEach((e) => {
            //document.getElementById(e).style.display = "none";
            document.querySelectorAll('[data-id="' + e + '"]')[0].style.display = "none";
        });
        document.querySelectorAll('[data-id="' + val + '"]')[0].style.display = "block";
        //document.getElementById(val).style.display = "block";
        this.setState({
            page: val,
            currentPageNo: pgno,
        });
    };
    contactFormSubmit = (e) => {
        e.preventDefault();
        var name = document.getElementById("nameContact").value;
        var email = document.getElementById("emailContact").value;
        var subject = document.getElementById("subjectContact").value;
        var message = document.getElementById("messageContact").value;

        let formData = new FormData();
        var info = this.props.dataState.url + "CVContactForm.php";
        var id = this.props.dataState.id;
        var userName = this.props.dataState.domain;
        formData.append('id', id);
        formData.append('userName', userName);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('subject', subject);
        formData.append('message', message);
        // alert(formData);

        Axios.post(info, formData)
            .then((res) => {
                // alert(res.data);
                document.getElementById("contact-form").reset();
                document.getElementById("contactMessage").style.display = "block";
                setTimeout(function () {
                    document.getElementById("contactMessage").style.display = "none";
                }, 3000);
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render(props) {
        return (
            <div id="lightcv">
                <div className="page">
                    <div className="page-content">
                        <header id="site_header" className="header mobile-menu-hide">
                            <div className="header-content">
                                <div className="header-photo">
                                    {this.props.dataState.profileImage ? (
                                        <img src={"https://www.digitizedresume.com/api/user/assets/profile/" + this.props.dataState.profileImage} alt={this.props.dataState.name} />
                                    ) : (
                                        <img src="img/main_photo.jpg" alt="Alex Smith" />
                                    )}
                                </div>
                                <div className="header-titles">
                                    <h2>{this.props.dataState.name}</h2>
                                    <h4>{this.props.dataState.designation}</h4>
                                </div>
                            </div>

                            <ul className="main-menu">
                                <li className="active">
                                    <a
                                        href="#home"
                                        onClick={() => this.switchPage("home", 0)}
                                        className="nav-anim"
                                    >
                                        <span className="menu-icon lnr lnr-home"></span>
                                        <span className="link-text">Home</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => this.switchPage("about-me", 1)}
                                        href="#about-me"
                                        className="nav-anim"
                                    >
                                        <span className="menu-icon lnr lnr-user"></span>
                                        <span className="link-text">About Me</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => this.switchPage("resume", 2)}
                                        href="#resume"
                                        className="nav-anim"
                                    >
                                        <span className="menu-icon lnr lnr-graduation-hat"></span>
                                        <span className="link-text">Resume</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => this.switchPage("portfolio", 3)}
                                        href="#portfolio"
                                        className="nav-anim"
                                    >
                                        <span className="menu-icon lnr lnr-briefcase"></span>
                                        <span className="link-text">Portfolio</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => this.switchPage("video", 4)}
                                        href="#video"
                                        className="nav-anim"
                                    >
                                        <span className="menu-icon lnr far fa-play-circle"></span>
                                        <span className="link-text">Video</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => this.switchPage("certificate", 5)}
                                        href="#certificate"
                                        className="nav-anim"
                                    >
                                        <span className="menu-icon lnr far fa-sticky-note"></span>
                                        <span className="link-text">Certificate</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => this.switchPage("project", 7)}
                                        href="#project"
                                        className="nav-anim"
                                    >
                                        <span className="menu-icon lnr fa fa-tasks"></span>
                                        <span className="link-text">Project</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => this.switchPage("contact", 6)}
                                        href="#contact"
                                        className="nav-anim"
                                    >
                                        <span className="menu-icon lnr lnr-envelope"></span>
                                        <span className="link-text">Contact</span>
                                    </a>
                                </li>
                            </ul>

                            {/* <div className="social-links">
                                <ul>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div> */}

                            <div className="header-buttons">
                                {this.props.dataState.cv ? (<a href={"https://www.digitizedresume.com/api/user/assets/cv/" + this.props.dataState.cv} target="_blank" className="Downloadcv shadow">
                                    Download CV
                                </a>) : false}
                            </div>
                        </header>

                        {/* <!-- Mobile Navigation --> */}
                        <div className="menu-toggle">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        {/* <!-- End Mobile Navigation --> */}

                        <div className="content-area">
                            <div className="animated-sections">
                                {/* <!-- Home Subpage --> */}
                                <section
                                    id="home"
                                    data-id="home"
                                    className="animated-section start-page"
                                >
                                    <div className="section-content vcentered">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div className="title-block">
                                                    <h2>{this.props.dataState.name}</h2>
                                                    <div className="owl-carousel text-rotation">
                                                        <div className="item">
                                                            <div className="sp-subtitle">{this.props.dataState.designation}</div>
                                                        </div>

                                                        {/* <div className="item">
                              <div className="sp-subtitle">
                                Frontend-developer
                              </div>
                            </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* <!-- End of Home Subpage --> */}

                                {/* <!-- About Me Subpage --> */}
                                <section
                                    id="about"
                                    data-id="about-me"
                                    className="animated-section"
                                >
                                    <div className="page-title">
                                        <h2>
                                            About <span>Me</span>
                                        </h2>
                                    </div>

                                    <div className="section-content">
                                        {/* <!-- Personal Information --> */}
                                        <div className="row">
                                            {this.props.dataState.description !== null && this.props.dataState.description !== "null" ? (
                                                <div className="col-xs-12 col-sm-7">
                                                    <p className="about_content">
                                                        {this.props.dataState.description}
                                                    </p>
                                                </div>
                                            ) : false}

                                            <div className="col-xs-12 col-sm-5">
                                                <div className="info-list">
                                                    <ul>
                                                        <li>
                                                            <span className="title">Age</span>
                                                            <span className="value">{this.props.dataState.dob}</span>
                                                        </li>

                                                        <li>
                                                            <span className="title">Residence</span>
                                                            <span className="value">{this.props.dataState.location}</span>
                                                        </li>

                                                        <li>
                                                            <span className="title">Address</span>
                                                            <span className="value">
                                                                {this.props.dataState.address}
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <span className="title">e-mail</span>
                                                            <span className="value">
                                                                <a href={"mailto:" + this.props.dataState.email}>{this.props.dataState.email}</a>
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <span className="title">Phone</span>
                                                            <span className="value">{this.props.dataState.phone}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End of Personal Information --> */}

                                        <div className="white-space-50"></div>

                                        {/* <!-- Services --> */}
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="block-title">
                                                    <h3>
                                                        What <span>I Do</span>
                                                    </h3>
                                                </div>
                                            </div>
                                            {this.props.dataState.workInfo !== null && this.props.dataState.workInfo !== "null" ? (
                                                <div className="col-md-12">
                                                    <p className="about_content">
                                                        {this.props.dataState.workInfo}
                                                    </p>
                                                </div>
                                            ) : false}
                                        </div>

                                        <div className="white-space-50"></div>



                                        {/* <!-- Testimonials --> */}
                                        <div class="row mb-0">
                                            {this.props.dataState.testimonial.length > 0 ? (
                                                <div class="col-xs-12 col-sm-12">
                                                    <div class="block-title mb-0">
                                                        <h3><span>Testimonials</span></h3>
                                                    </div>
                                                </div>) : false}
                                        </div>

                                        <div class="row">
                                            <div class="col-xs-12 col-sm-12">
                                                <div class="testimonials owl-carousel">
                                                    {this.props.dataState.testimonial.length === 0 ? (
                                                        false
                                                    ) : (
                                                        this.props.dataState.testimonial.map((ele, i) => {
                                                            return (
                                                                <div class="testimonial mt-0">
                                                                    {/* <div class="img">
                                                                        <img src="img/testimonials/testimonial-1.jpg" alt="Alex Smith" />
                                                                    </div> */}
                                                                    <div class="text mt-2">
                                                                        <p>{ele.description}</p>
                                                                    </div>

                                                                    <div class="author-info">
                                                                        <h4 class="author">{ele.name}</h4>
                                                                        <div class="icon">
                                                                            <i class="fas fa-quote-right"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );

                                                        })
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!-- End of Testimonials --> */}





                                        {/* <!-- Internship --> */}
                                        <div class="row mt-4 mb-0">
                                            {this.props.dataState.internship.length > 0 ? (
                                                <div class="col-xs-12 col-sm-12">
                                                    <div class="block-title">
                                                        <h3><span>Internship</span></h3>
                                                    </div>
                                                </div>) : false}
                                        </div>

                                        <div class="row">
                                            <div class="col-xs-12 col-sm-12">
                                                <div class="testimonials owl-carousel">
                                                    {/* <!-- Internship  1 --> */}
                                                    {this.props.dataState.internship.length === 0 ? (
                                                        false
                                                    ) : (
                                                        this.props.dataState.internship.map((ele, i) => {
                                                            return (
                                                                <div class="testimonial mt-0">
                                                                    <div class="author-info">
                                                                        <h4 class="author" style={{ fontSize: '22px' }}>{ele.companyName}</h4>
                                                                        <h5 class="company mt-1" style={{ fontSize: '16px', color: '#333131' }}>{"Designation: " + ele.department}</h5>
                                                                    </div>
                                                                    <div class="text m-2">
                                                                        <p>{ele.description}</p>
                                                                    </div>
                                                                </div>
                                                            );

                                                        })
                                                    )}
                                                    {/* <!-- End of Internship  1 --> */}

                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End of Internship --> */}
                                    </div>
                                </section>
                                {/* <!-- End of About Me Subpage --> */}

                                {/* <!-- Resume Subpage --> */}
                                <section
                                    id="resume"
                                    data-id="resume"
                                    className="animated-section"
                                >
                                    <div className="page-title">
                                        <h2>Resume</h2>
                                    </div>

                                    <div className="section-content">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-7">
                                                <div className="block-title">
                                                    <h3>Education</h3>
                                                </div>

                                                <div className="timeline timeline-second-style clearfix">

                                                    {this.props.dataState.highestQualification !== null && this.props.dataState.highestQualification !== "null" ? (
                                                        <div className="timeline-item clearfix">
                                                            <div className="left-part">
                                                                <h5 className="item-period">{this.props.dataState.passingYear1}</h5>
                                                                <span className="item-company">
                                                                    {this.props.dataState.highestEducation}
                                                                </span>
                                                                <span className="item-company">
                                                                    {this.props.dataState.universityCollege1}
                                                                </span>
                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className="right-part">
                                                                <h4 className="item-title">
                                                                    {this.props.dataState.highestQualification}
                                                                </h4>
                                                                <p>
                                                                    Passing Year — {this.props.dataState.passingYear1}<br />
                                                                    Specialization — {this.props.dataState.specialization1}<br />
                                                                    Course Type — {this.props.dataState.courseType1}<br />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : false}

                                                    {this.props.dataState.qualification2 !== null && this.props.dataState.qualification2 !== "null" ? (
                                                        <div className="timeline-item clearfix">
                                                            <div className="left-part">
                                                                <h5 className="item-period">{this.props.dataState.passingYear2}</h5>
                                                                <span className="item-company">
                                                                    {this.props.dataState.qualification2}
                                                                </span>
                                                                <span className="item-company">
                                                                    {this.props.dataState.universityCollege2}
                                                                </span>
                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className="right-part">
                                                                <h4 className="item-title">
                                                                    {this.props.dataState.course2}
                                                                </h4>
                                                                <p>
                                                                    Passing Year — {this.props.dataState.passingYear2}<br />
                                                                    Specialization — {this.props.dataState.specialization2}<br />
                                                                    Course Type — {this.props.dataState.courseType2}<br />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : false}

                                                    {this.props.dataState.qualification3 !== null && this.props.dataState.qualification3 !== "null" ? (
                                                        <div className="timeline-item clearfix">
                                                            <div className="left-part">
                                                                <h5 className="item-period">{this.props.dataState.passingYear3}</h5>
                                                                <span className="item-company">
                                                                    {this.props.dataState.universityCollege3}
                                                                </span>
                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className="right-part">
                                                                <h4 className="item-title">
                                                                    {this.props.dataState.qualification3}
                                                                </h4>
                                                                <p>
                                                                    Passing Year — {this.props.dataState.passingYear3}<br />
                                                                    Specialization — {this.props.dataState.specialization3}<br />
                                                                    Course Type — {this.props.dataState.courseType3}<br />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : false}


                                                </div>

                                                <div className="white-space-50"></div>
                                            </div>

                                            {/* <!-- Skills & Certificates --> */}
                                            <div className="col-xs-12 col-sm-5">
                                                {/* <!-- Design Skills --> */}
                                                <div className="block-title">
                                                    <h3>
                                                        <span>Skills</span>
                                                    </h3>
                                                </div>

                                                <div className="skills-info skills-second-style">
                                                    {this.props.dataState.skills.length === 0 ? (
                                                        false
                                                    ) : (
                                                        this.props.dataState.skills.map((ele, i) => {
                                                            return (
                                                                <>
                                                                    <div className="skill clearfix">
                                                                        <h4>{ele.name}</h4>
                                                                        <div className="skill-value">{ele.rating + "%"}</div>
                                                                    </div>
                                                                    <div className="skill-container skill-1">
                                                                        <div className="skill-percentage"></div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                                {/* <!-- End of Design Skills --> */}

                                                <div className="white-space-10"></div>

                                                {/* <!-- Knowledges --> */}
                                                {/* <div className="block-title">
                                                    <h3>Knowledges</h3>
                                                </div>

                                                <ul className="knowledges">
                                                    <li>Marketing</li>
                                                    <li>Print</li>
                                                    <li>Digital Design</li>
                                                    <li>Social Media</li>
                                                    <li>Time Management</li>
                                                    <li>Communication</li>
                                                    <li>Problem-Solving</li>
                                                    <li>Social Networking</li>
                                                    <li>Flexibility</li>
                                                </ul> */}
                                                {/* <!-- End of Knowledges --> */}
                                            </div>
                                            {/* <!-- End of Skills & Certificates --> */}
                                        </div>

                                        {/* <div className="white-space-50"></div> */}

                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="block-title">
                                                    <h3>Experience</h3>
                                                </div>

                                                <div className="timeline timeline-second-style clearfix">
                                                    {this.props.dataState.currentCompany ? (
                                                        <div className="timeline-item clearfix">
                                                            <div className="left-part">
                                                                <h5 className="item-period">{this.props.dataState.startDate} till {this.props.dataState.endDate ? (
                                                                    <span className="item-period"> {this.props.dataState.endDate}</span>
                                                                ) : (
                                                                    <span style={{ color: 'black' }}>Present</span>
                                                                )}</h5>
                                                                <span className="item-company">{this.props.dataState.currentCompany}</span>
                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className="right-part">
                                                                <h4 className="item-title">
                                                                    {this.props.dataState.currentDesignation}
                                                                </h4>
                                                                {this.props.dataState.role !== "null" && this.props.dataState.role !== null ? (
                                                                    <p>
                                                                        {this.props.dataState.role}
                                                                    </p>
                                                                ) : false}
                                                            </div>
                                                        </div>
                                                    ) : false}

                                                    {this.props.dataState.pastWorkExp.length === 0 ? (
                                                        false
                                                    ) : (
                                                        this.props.dataState.pastWorkExp.map((ele, i) => {
                                                            return (
                                                                <div className="timeline-item clearfix">
                                                                    <div className="left-part">
                                                                        <h5 className="item-period">{ele.pastStartDate} to {ele.pastEndDate}</h5>
                                                                        <span className="item-company">{ele.pastCompany}</span>
                                                                    </div>
                                                                    <div className="divider"></div>
                                                                    <div className="right-part">
                                                                        <h4 className="item-title">
                                                                            {ele.pastDesignation}
                                                                        </h4>
                                                                        <p>
                                                                            {ele.pastRole}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="white-space-10"></div>
                                        <div className="white-space-10"></div>
                                    </div>
                                </section>
                                {/* <!-- End of Resume Subpage --> */}

                                {/* <!-- Portfolio Subpage --> */}
                                <section
                                    id="portfolio"
                                    data-id="portfolio"
                                    className="animated-section"
                                >
                                    <div className="page-title">
                                        <h2>Portfolio</h2>
                                    </div>

                                    <div className="section-content">
                                        <div className="row">
                                            {this.props.dataState.portfolio.length === 0 ? (
                                                <h5 className="text-center text-dark">Portfolio is not uploaded yet!</h5>
                                            ) : (
                                                this.props.dataState.portfolio.map((ele, i) => {
                                                    return (
                                                        <>
                                                            <div className="col-md-4 portfolioContainer">
                                                                <figure className="item lbaudio" data-groups='["category_all", "category_portfolio"]'>
                                                                    <div className="portfolio-item-img">
                                                                        <a target="_blank" href={"https://www.digitizedresume.com/api/user/assets/portfolio/" + ele.file}><img
                                                                            src={"https://www.digitizedresume.com/api/user/assets/portfolio/" + ele.file}
                                                                            alt={ele.title}
                                                                            title={ele.title}
                                                                        /></a>
                                                                    </div>
                                                                    <h4 className="name">{ele.title}</h4>
                                                                    <span className="category text-dark">{ele.description}</span>
                                                                </figure>
                                                            </div>
                                                        </>
                                                    );

                                                })
                                            )}
                                        </div>
                                    </div>
                                </section>
                                {/* <!-- End of Portfolio Subpage --> */}

                                {/* <!-- project Subpage --> */}
                                <section
                                    id="project"
                                    data-id="project"
                                    className="animated-section"
                                >
                                    <div className="page-title">
                                        <h2>Project</h2>
                                    </div>

                                    <div className="section-content">

                                        <div className="row">
                                            {this.props.dataState.project.length === 0 ? (
                                                <h5 className="text-center text-dark">Project is not uploaded yet!</h5>
                                            ) : (
                                                this.props.dataState.project.map((ele, i) => {
                                                    return (
                                                        <div class="col-xs-12 col-sm-6">
                                                            <div class="fun-fact gray-default">
                                                                <h2>{ele.name}</h2>
                                                                <span class="fun-fact-block-value mt-0">{ele.technologyUsed}</span>
                                                                <p class="fun-fact-block-text text-dark">{ele.description}</p>
                                                            </div>
                                                        </div>
                                                    );

                                                })
                                            )}
                                        </div>
                                    </div>
                                </section>
                                {/* <!-- End of project Subpage --> */}

                                {/* <!-- video Subpage --> */}
                                <section
                                    id="video"
                                    data-id="video"
                                    className="animated-section"
                                >
                                    <div className="page-title">
                                        <h2>Video</h2>
                                    </div>

                                    <div className="section-content">
                                        <div className="row">
                                            {this.props.dataState.video.length === 0 ? (
                                                <h5 className="text-center text-dark">Video is not uploaded yet!</h5>
                                            ) : (
                                                this.props.dataState.video.map((ele, i) => {
                                                    return (
                                                        <>
                                                            {ele.fileType === "URL" ? (
                                                                <div className="col-md-4 portfolioContainer">
                                                                    <figure className="item lbaudio" data-groups='["category_all", "category_portfolio"]'>
                                                                        <div className="portfolio-item-img">
                                                                            <ReactPlayer
                                                                                width="100%"
                                                                                height="100%"
                                                                                controls
                                                                                allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
                                                                                allowfullscreen
                                                                                url={ele.file}
                                                                            />
                                                                        </div>
                                                                        <h4 className="name">{ele.title}</h4>
                                                                        <span className="category text-dark">{ele.description}</span>
                                                                    </figure>
                                                                </div>

                                                            ) : (
                                                                <div className="col-md-4 portfolioContainer">
                                                                    <figure className="item lbaudio" data-groups='["category_all", "category_portfolio"]'>
                                                                        <div className="portfolio-item-img">
                                                                            <ReactPlayer
                                                                                width="100%"
                                                                                height="100%"
                                                                                controls
                                                                                allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
                                                                                allowfullscreen
                                                                                url={"https://www.digitizedresume.com/api/user/assets/video/" + ele.file}
                                                                            />
                                                                        </div>
                                                                        <h4 className="name">{ele.title}</h4>
                                                                        <span className="category text-dark">{ele.description}</span>
                                                                    </figure>
                                                                </div>
                                                            )}
                                                        </>
                                                    );

                                                })
                                            )}
                                        </div>
                                    </div>
                                </section>
                                {/* <!-- End of video Subpage --> */}

                                {/* <!-- certificate Subpage --> */}
                                <section
                                    id="certificate"
                                    data-id="certificate"
                                    className="animated-section"
                                >
                                    <div className="page-title">
                                        <h2>Certificate</h2>
                                    </div>

                                    <div className="section-content">
                                        <div className="row">
                                            {this.props.dataState.certificate.length === 0 ? (
                                                <h5 className="text-center text-dark">Certificate is not uploaded yet!</h5>
                                            ) : (
                                                this.props.dataState.certificate.map((ele, i) => {
                                                    return (
                                                        <>
                                                            <div className="col-md-4 portfolioContainer">
                                                                <figure className="item lbaudio" data-groups='["category_all", "category_portfolio"]'>
                                                                    <div className="portfolio-item-img">
                                                                        <a target="_blank" href={"https://www.digitizedresume.com/api/user/assets/certificate/" + ele.file}><img
                                                                            src={"https://www.digitizedresume.com/api/user/assets/certificate/" + ele.file}
                                                                            alt={ele.certificateName}
                                                                            title={ele.certificateName}
                                                                        /></a>
                                                                    </div>
                                                                    <h4 className="name">{ele.certificateName}</h4>
                                                                    <span className="category text-dark">{ele.description}</span>
                                                                </figure>
                                                            </div>
                                                        </>
                                                    );

                                                })
                                            )}
                                        </div>
                                    </div>
                                </section>
                                {/* <!-- End of certificate Subpage --> */}

                                {/* <!-- Contact Subpage --> */}
                                <section
                                    id="contact"
                                    data-id="contact"
                                    className="animated-section"
                                >
                                    <div className="page-title">
                                        <h2>Contact</h2>
                                    </div>


                                    <div className="section-content">
                                        <div className="row">
                                            {/* <!-- Contact Info --> */}
                                            <div className="col-xs-12 col-sm-5">
                                                <div className="lm-info-block gray-default">
                                                    <i className="lnr lnr-map-marker"></i>
                                                    <h4>{this.props.dataState.location}</h4>
                                                    <span className="lm-info-block-value"></span>
                                                    <span className="lm-info-block-text"></span>
                                                </div>

                                                <div className="lm-info-block gray-default">
                                                    <i className="lnr lnr-phone-handset"></i>
                                                    <h4>{this.props.dataState.phone}</h4>
                                                    <span className="lm-info-block-value"></span>
                                                    <span className="lm-info-block-text"></span>
                                                </div>

                                                <div className="lm-info-block gray-default">
                                                    <i className="lnr lnr-envelope"></i>
                                                    <h4>
                                                        <a
                                                            href={"mailto:" + this.props.dataState.email}
                                                        >
                                                            {this.props.dataState.email}
                                                        </a>
                                                    </h4>
                                                    <span className="lm-info-block-value"></span>
                                                    <span className="lm-info-block-text"></span>
                                                </div>

                                                <div className="lm-info-block gray-default">
                                                    <i className="lnr lnr-checkmark-circle"></i>
                                                    <h4>{this.props.dataState.availability}</h4>
                                                    <span className="lm-info-block-value"></span>
                                                    <span className="lm-info-block-text"></span>
                                                </div>
                                            </div>
                                            {/* <!-- End of Contact Info --> */}

                                            {/* <!-- Contact Form --> */}
                                            <div className="col-xs-12 col-sm-7">
                                                <div className="block-title">
                                                    <h3>
                                                        How Can I <span>Help You?</span>
                                                    </h3>
                                                </div>

                                                <form
                                                    id="contact-form"
                                                    className="contact-form"
                                                    onSubmit={this.contactFormSubmit}
                                                >
                                                    <div className="messages"></div>

                                                    <div className="controls two-columns">
                                                        <div className="fields clearfix">
                                                            <div className="left-column">
                                                                <div className="form-group form-group-with-icon">
                                                                    <input
                                                                        id="nameContact"
                                                                        type="text"
                                                                        name="nameContact"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        required="required"
                                                                        data-error="Name is required."
                                                                    />
                                                                    <label>Full Name</label>
                                                                    <div className="form-control-border"></div>
                                                                    <div className="help-block with-errors"></div>
                                                                </div>

                                                                <div className="form-group form-group-with-icon">
                                                                    <input
                                                                        id="emailContact"
                                                                        type="email"
                                                                        name="emailContact"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        required="required"
                                                                        data-error="Valid email is required."
                                                                    />
                                                                    <label>Email Address</label>
                                                                    <div className="form-control-border"></div>
                                                                    <div className="help-block with-errors"></div>
                                                                </div>

                                                                <div className="form-group form-group-with-icon">
                                                                    <input
                                                                        id="subjectContact"
                                                                        type="text"
                                                                        name="subjectContact"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        required="required"
                                                                        data-error="Subject is required."
                                                                    />
                                                                    <label>Subject</label>
                                                                    <div className="form-control-border"></div>
                                                                    <div className="help-block with-errors"></div>
                                                                </div>
                                                            </div>
                                                            <div className="right-column">
                                                                <div className="form-group form-group-with-icon">
                                                                    <textarea
                                                                        id="messageContact"
                                                                        name="messageContact"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        rows="7"
                                                                        required="required"
                                                                        data-error="Please, leave me a message."
                                                                    ></textarea>
                                                                    <label>Message</label>
                                                                    <div className="form-control-border"></div>
                                                                    <div className="help-block with-errors"></div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="g-recaptcha"
                                                            data-sitekey="6LdqmCAUAAAAAMMNEZvn6g4W5e0or2sZmAVpxVqI"
                                                        ></div>

                                                        <input
                                                            type="submit"
                                                            className="button btn-send"
                                                            value="Send Message"
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                            {/* <!-- End of Contact Form --> */}
                                        </div>
                                    </div>
                                </section>
                                {/* <!-- End of Contact Subpage --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
