import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Menu from "./component/navigation/menu";
import Home1 from "./component/home1/home1";
import Talent from "./component/talent/talent";
import Employee from "./component/employee/employee";
import Footer from "./component/footer/footer";
import WhyUs from "./component/whyus/whyUs";
import Testimonial from "./component/testimonial/testimonial";
import EmployeeLogin from "./component/login/employee-login";
import EmployerLogin from "./component/login/employer-login";
import SignUp from "./component/signup/signup";
import Contact from "./component/contact/contact";
import EmployerSignUp from "./component/employeer/employer-signup";
import AboutUS from "./component/aboutus/about";
import PhoneLoginPage from "./component/PhoneLogin/loginPage";
import TestimonialSec from "./component/Testimonial_1/testimonial_sec";
import Error404 from "./component/error-pages/Error404";
import PrivacyPolicy from "./component/legalDocument/PrivacyPolicy";
import TermOfCondition from "./component/legalDocument/TermsCondition";

function App() {
  useEffect(() => {
    AOS.init();
  });

  return (

    <Router>
      <Menu />
      <Switch>
        <Route exact path="/" component={Home1} />
        <Route exact path="/talent" component={Talent} />
        <Route exact path="/employee" component={Employee} />
        <Route exact path="/footer" component={Footer} />
        <Route exact path="/why_us" component={WhyUs} />
        <Route exact path="/about_us" component={AboutUS} />
        <Route exact path="/testimonial" component={Testimonial} />
        <Route exact path="/user_testimonial" component={TestimonialSec} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/employee-login" component={EmployeeLogin} />
        <Route exact path="/employer-login" component={EmployerLogin} />
        <Route exact path="/create_resume" component={SignUp} />
        <Route exact path="/employer-signup" component={EmployerSignUp} />
        <Route exact path="/login" component={PhoneLoginPage} />

        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-of-condition" component={TermOfCondition} />
        
        
        <Route component={Error404} />  
       
      </Switch>

       


    </Router>
  );
}

export default App;
